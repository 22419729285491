import React, { useRef, useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import DoneIcon from "@mui/icons-material/Done";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { keyframes } from "@mui/system";
import { Chip } from "@mui/material";
import Divider from "@mui/material/Divider";

// Define the zoom-in animation
const zoomIn = keyframes`
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export default function SingleProject({
  project_img,
  project_title,
  project_description,
  style,
  status,
  technologies,
  type,
  role,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current); // Stop observing after it's visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Card
      sx={{
        width: 345,
        m: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        opacity: isVisible ? 1 : 0,
        animation: isVisible ? `${zoomIn} 1s ease-out` : "none",
        ...style, // Apply additional styles passed via props
      }}
      ref={ref}
    >
      <CardMedia sx={{ height: 140 }} image={project_img} title="project" />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {project_title}
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {project_description}
        </Typography>
      </CardContent>

      <Box
        sx={{
          maxWidth: "100%",
          display: "flex",
          justifyContent: "space-between",
          p: 3,
        }}
      >
        <Button size="small" onClick={handleClick}>
          Explore More
        </Button>
        <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            p: 2,
            width: "230px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* <Chip label={type}></Chip> */}
          <Box>
            <Typography variant="subtitle1">{type}</Typography>
          </Box>

          <Divider sx={{ width: "100%", mt: 1, mb: 1 }} />
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            {"I'm " + role + " in this Project"}
          </Typography>

          <Divider sx={{ width: "100%", mt: 1, mb: 1 }} />
          <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
            Technologies
          </Typography>

          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 1,
              mt: 1,
              flexWrap: "wrap",
            }}
          >
            {technologies.map((tech, index) => (
              <>{tech.icon}</>
            ))}
          </Box>
        </Box>
        {/* <Typography sx={{ p: 2 }}>The content of the Popover.</Typography> */}
      </Popover>
        <Button
          size="small"
          startIcon={status === "Done" ? <DoneIcon /> : <AutorenewIcon />}
          variant="outlined"
          sx={{ borderRadius: "50px" }}
          disabled
        >
          {status == "Done" ? "Done" : "Ongoing"}
        </Button>
        {/* <Chip label="React" color="error" /> */}
      </Box>
      
      {/* <CardActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
        
      </CardActions> */}
    </Card>
  );
}
