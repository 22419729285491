import React, { useRef, useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import uom from "../assets/images/uom.jpg";
import uomcover from "../assets/images/uomcover.jpg";
import rathnawali from "../assets/images/rathnawali.jpeg";
import rathnawalicover from "../assets/images/rathnawalicover.jpg";
import bci from "../assets/images/bci.jpeg";
import bcicover from "../assets/images/bcicover.jpg";
import { SingleEducation } from "./SingleEducation"

const EducationData = [
    {
        img: uom,
        type: "Higher Education",
        cover_img: uomcover,
        title: "University of Moratuwa",
        subtitle: "",
        description: <>BSc. (Hons) in Information Technology & Management <br/><br/> Academic Year 2020/2021 <br/><br/> 3.023 Current GPA</>,
    },
    {
        img: rathnawali,
        type: "Secondary Education",
        cover_img: rathnawalicover,
        title: <>Rathnawali Balika<br/>Gampaha</>,
        subtitle: "",
        description: <>ALs - 2020<br/>Physical Science Stream<br/><br/>Comined Maths - B <br/>Physics - B<br/>Chemistry - B </>,
    },
    {
      img: bci,
      type: "Diploma",
      cover_img: bcicover,
      title: <>BCI Campus<br/>Negombo</>,
      subtitle: "",
      description: <> Aquinas Diploma in English Language & Literature<br/><br/><br/><br/><br/> </>,
  }
]
// Project data

export default function Education() {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current); // Stop observing after it's visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the section is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        paddingTop: 10,
        opacity: isVisible ? 1 : 0,
        transition: "opacity 1s ease-out",
      }}
      ref={ref}
    >
      <Typography variant="h4" sx={{ textAlign: "center", fontWeight: 550 }}>
        Where I Learnt from
      </Typography>

      <Typography variant="h5" sx={{ marginBottom: 3 }}>
        My{" "}
        <span
          style={{
            color: "#007F73",
            fontFamily: "Brush Script MT",
            fontWeight: "bold",
          }}
        >
          Education
        </span>
      </Typography>

      <Box
        sx={{
          maxWidth: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 8,
          padding: 2
        }}
      >
        {EducationData.map((ed, index) => (
          <SingleEducation
            key={index}
            img={ed.img}
            title={ed.title}
            type={ed.type}
            subtitle={ed.subtitle}
            description={ed.description}
            cover_img={ed.cover_img}
          />
        ))}
      </Box>
    </Box>
  );
}
