import React from "react";
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Map from "./Map";

export default function Contact() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        paddingTop: 10,
      }}
    >
      <Typography variant="h4" sx={{ textAlign: "center", fontWeight: 550 }}>
        Connect with Me
      </Typography>

      <Typography variant="h5" sx={{ marginBottom: 3 }}>
        My{" "}
        <span
          style={{
            color: "#007F73",
            fontFamily: "Brush Script MT",
            fontWeight: "bold",
          }}
        >
          Contacts
        </span>
      </Typography>

      <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "500px" },
            display: "flex",
            flexDirection: "column",
            gap: 2,
            ml: 5,
            mr: 5,
            p: { xs: 2, sm: 2, md: 10 },
          }}
        >
          <TextField
            label="Your Name"
            variant="outlined"
            sx={{ borderRadius: "50px", zIndex: 0 }}
          />
          <TextField
            label="Your Mobile Number"
            variant="outlined"
            sx={{ borderRadius: "50px", zIndex: 0 }}
          />
          <TextField
            label="Your E-mail"
            variant="outlined"
            sx={{ borderRadius: "50px", zIndex: 0 }}
          />
          <TextField
            label="Message"
            rows={5}
            multiline
            variant="outlined"
            sx={{ borderRadius: "50px", zIndex: 0 }}
          />
          <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              sx={{ borderRadius: "50px", width: "150px", height: "50px" }}
            >
              Send
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            width: { xs: "100%", sm: "100%", md: "500px" },
            height: { xs: "300px", sm: "300px", md: "500px" },
            p: { xs: 2, sm: 2, md: 10 },
            ml: 5,
            mr: 5,
          }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126670.33645488783!2d79.77567979076676!3d7.189611382366534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2ee9c6bb2f73b%3A0xa51626e908186f3e!2sNegombo!5e0!3m2!1sen!2slk!4v1726844446917!5m2!1sen!2slk"
            width="100%"
            height="100%"
            // style={"border:0;"}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
}
