import React, { useRef, useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import SingleProject from "./SingleProject";
import hw from "../assets/images/hw.png";
import plazer from "../assets/images/plazer.png";
import anythink from "../assets/images/anythink.png";
import ayurva from "../assets/images/ayurva.png";
import { SiMongodb } from "react-icons/si";
import { FaReact } from "react-icons/fa";
import { FaNodeJs } from "react-icons/fa";
import { SiExpress } from "react-icons/si";
import { IoLogoJavascript } from "react-icons/io5";
import { SiTypescript } from "react-icons/si";
import { SiNestjs } from "react-icons/si";
import { IoLogoFirebase } from "react-icons/io5";
import { RiNextjsFill } from "react-icons/ri";
import { BiLogoPostgresql } from "react-icons/bi";
import { TbBrandReactNative } from "react-icons/tb";

// Project data
const ProjectData = [
  {
    project_img: hw,
    project_title: "Plant Growth Chamber",
    project_description: "An innovative process let the seeds to grow in a controlled and sealed environment by controlling the atmosphere around the plants.",
    status: "Done",
    technologies: [
      { icon: "Arduino | ", name: "JavaScript" },
      { icon: "RTC Module | ", name: "TypeScript" },
      { icon: "DHT22 Sensor | ", name: "Next.js" },
      { icon: "Soil Moisture Sensor", name: "MongoDB" },
    ],
    role: "a Member",
    type: "Level I Hardware Project"
  },
  {
    project_img: plazer,
    project_title: "Plazer Multi Service Web Portal",
    project_description: "A multi-service web platform which provides users to access different kind of web application services at same place using the same login credentials.",
    status: "Done",
    technologies: [
      { icon: <IoLogoJavascript />, name: "JavaScript" },
      { icon: <SiTypescript />, name: "TypeScript" },
      { icon: <FaReact />, name: "Next.js" },
      { icon: <BiLogoPostgresql />, name: "MongoDB" },
      { icon: <SiNestjs />, name: "Node.js" },
    ],
    role: "the Leader",
    type: "Level II Software Project"
  },
  {
    project_img: anythink,
    project_title: "AnyThink",
    project_description: "A mobile app that allows the user to manage his/her day-to-day work, manage his/her budget, maintain a personal diary, and manage important notes and documents. Users can also periodically get budget and task analysis to measure their performance.",
    status: "Ongoing",
    technologies: [
      { icon: <IoLogoJavascript />, name: "JavaScript" },
      { icon: <TbBrandReactNative />, name: "Next.js" },
      { icon: <SiMongodb />, name: "MongoDB" },
      { icon: <SiExpress />, name: "Node.js" },
    ],
    role: "the Fullstack Developer",
    type: "Individual Project"
  },
  {
    project_img: ayurva,
    project_title: "Ayurva",
    project_description: "A mobile responsive web platform helps the user to find the required medicine with the aid of a prescription. Users can order the required medicine from the nearest pharmacies which are registered on the platform.",
    status: "Ongoing",
    technologies: [
      { icon: <SiTypescript />, name: "JavaScript" },
      { icon: <FaReact />, name: "Next.js" },
      { icon: <BiLogoPostgresql />, name: "MongoDB" },
      { icon: <SiNestjs />, name: "Node.js" },
    ],
    role: "the Fullstack Developer",
    type: "Level III Individual Project"
  },
];

export default function Projects() {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current); // Stop observing after it's visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the section is visible
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        paddingTop: 10,
        opacity: isVisible ? 1 : 0,
        transition: "opacity 1s ease-out",
      }}
      ref={ref}
    >
      <Typography variant="h4" sx={{ textAlign: "center", fontWeight: 550 }}>
        What have I Done
      </Typography>

      <Typography variant="h5" sx={{ marginBottom: 3 }}>
        My{" "}
        <span
          style={{
            color: "#007F73",
            fontFamily: "Brush Script MT",
            fontWeight: "bold",
          }}
        >
          Projects
        </span>
      </Typography>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        {ProjectData.map((project, index) => (
          <SingleProject
            project_img={project.project_img}
            project_title={project.project_title}
            project_description={project.project_description}
            key={index}
            style={{ animationDelay: `${index * 0.3}s`, animation: isVisible ? `zoomIn 1s ease-out` : 'none' }} // Apply animation if visible
            status={project.status}
            type={project.type}
            technologies={project.technologies}
            role={project.role}
          />
        ))}
      </Box>
    </Box>
  );
}
